<script setup lang="ts">

const emit = defineEmits<{
  (e: 'click'): void
}>();

function click() {
  emit('click');
}
</script>

<template>
  <button type="button" @click="click">
    <slot></slot>
  </button>
</template>

<style scoped>
button {
  @apply block w-full rounded-md px-3 py-3
  text-center text-sm font-semibold text-white
  bg-gradient-to-br from-cez-color to-rose-700
  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600
}

button:hover {
  @apply from-orange-500 to-rose-500
}

button:disabled {
  @apply from-orange-800 to-rose-800
  text-gray-500
}
</style>