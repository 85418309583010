import {Ref, ref} from "vue";
import {defineStore} from "pinia";
import {IExoUser} from "@/services/contracts/IExoUser";
import exoUserService from "@/services/ExoUserService";
import {auth0} from "@/services/support/auth0";

export const useExoUserStore
    = defineStore('exoUser', () => {

    const empty: IExoUser = {
        id: 0,
        ssoId: "",
        email: "",
        firstName: "",
        lastName: "",
        preferredEmail: "",
        ssoUsername: "",
        nickname: "",
        profileImageData: ""
    };

    const exoUser: Ref<IExoUser> = ref(empty);
    const profileImageChanged: Ref<Boolean> = ref(false);

    function load() {
        const ssoid = auth0.user.value?.sub as string;

        if (ssoid == undefined) {
            exoUser.value = empty;
            return;
        }

        exoUserService.get(ssoid).then(
            success => {
                console.log('Exouser loaded');
                exoUser.value = success.data;
                profileImageChanged.value = false;
                return Promise.resolve(success);
            },
            error => {
                console.error('Exouser load failed', error);
                exoUser.value = empty;
                profileImageChanged.value = false;
                return Promise.reject(error);
            })
    }

    return {exoUser, profileImageChanged, load}
})