<script setup lang="ts">

import {useExoUserStore} from "../stores/exouser";
import {computed} from "vue";

const exoUserStore = useExoUserStore();

const exoUser = computed(() => {
  return exoUserStore.exoUser;
})

</script>

<template>
  <router-link to="/profile">
    <div class="flex items-center pt-5 pb-3">

      <div>
        <img class="inline-block h-12 w-12 rounded-full border-orange-500 border-2"
             :src="exoUser.profileImageData == null ? '/assets/logo.png' : exoUser.profileImageData"
             alt=""/>
      </div>
      <div class="pl-3">
        <p>{{ exoUser.firstName + ' ' + exoUser.lastName }}</p>
        <p class="text-sm text-gray-500">{{ exoUser.nickname }}</p>
      </div>
    </div>
  </router-link>
</template>

<style scoped>

</style>