export function millisecondsToString(ms: number): string {
    const seconds = Math.floor((ms / 1000) % 60);
    const minutes = Math.floor((ms / 1000 / 60) % 60);

    return pad(minutes, 2) + ':' + pad(seconds, 2);
}

function pad(num: number, size: number) {
    let s = num.toString();
    while (s.length < size) s = '0' + s;
    return s;
}