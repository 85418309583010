<template>
  <PageLayout>
    <h1>Scoreboard</h1>
  </PageLayout>
</template>

<script setup lang="ts">
import PageLayout from "@/components/PageLayout.vue";
import {onMounted} from "vue";
import {useExoUserStore} from "@/stores/exouser";
import {useVersionStore} from "@/stores/version";

const exoUserStore = useExoUserStore();
const versionStore = useVersionStore();

onMounted(() => {
  versionStore.reloadOnVersionDifference();
  exoUserStore.load();
})
</script>

<style>
</style>