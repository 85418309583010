import {createApp} from 'vue'
import App from './App.vue'
import router from "./router";
import './index.css';
import {createPinia} from "pinia";
import {auth0} from "@/services/support/auth0";
import interceptor from "@/services/support/interceptor";
//import VueMathjax from 'vue-mathjax-next';

interceptor();

createApp(App)
    .use(router)
    .use(auth0)
    .use(createPinia())
    .mount('#app');
