<script setup lang="ts">
import {BeakerIcon, BoltIcon} from '@heroicons/vue/24/outline'
import {IQuizListItem} from "@/services/contracts/IQuizListItem";
import {useQuizListItemStore} from "@/stores/quiz";
import {QuizState} from "@/services/contracts/QuizState";

const quizList: Array<IQuizListItem> = [
  {
    id: 1,
    name: "Quiz #1",
    state: QuizState.COMPLETED,
    deadline: "30.12.2024 15:11",
    questionCount: 10,
    answerCount: 10
  },
  {
    id: 2,
    name: "Quiz #2",
    state: QuizState.IN_PROGRESS,
    deadline: "30.01.2025 12:30",
    questionCount: 9,
    answerCount: 3
  },
  {
    id: 3,
    name: "Quiz #3",
    state: QuizState.NEW,
    deadline: "30.02.2025 16:23",
    questionCount: 8,
    answerCount: 0
  },
  {
    id: 4,
    name: "Quiz #4",
    state: QuizState.EXPIRED,
    deadline: "11.12.2023 16:23",
    questionCount: 5,
    answerCount: 0
  }];

const quizStore = useQuizListItemStore();

function selectQuiz(quiz: IQuizListItem | null) {
  quizStore.changeQuiz(quiz);
}

</script>

<template>
  <div class="pb-3">
    <h1>Kvízy</h1>
    <router-link v-for="quiz in quizList"
                 to="/quiz"
                 :key="quiz.id"
                 @click="selectQuiz(quiz)"
                 class="quiz-box">
      <div class="head-part">
        <div>{{ quiz.name }}</div>
        <div class="text-xs">{{ quiz.deadline }}</div> {{ quiz.state }}
      </div>
      <div class="footer-part">
        <BeakerIcon aria-hidden="true" class="h-7 w-7 text-orange-500"/>
        {{ quiz.answerCount }}/{{ quiz.questionCount }}
        <BoltIcon aria-hidden="true" class="h-7 w-7 bg-orange-500 rounded-full p-1"/>
        {{ quiz.answerCount }}/{{ quiz.questionCount }}
      </div>
    </router-link>

  </div>
</template>

<style scoped>

</style>