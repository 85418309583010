<script setup lang="ts">
import {IQuiz} from "@/services/contracts/IQuiz";
import MyButton from "@/components/MyButton.vue";
import {BoltIcon, CalendarIcon, ClockIcon, DocumentIcon} from '@heroicons/vue/24/outline';
import {computed} from "vue";
import {QuizState} from "@/services/contracts/QuizState";
import {millisecondsToString} from "@/services/support/timeformat";

const props = defineProps<{
  quiz: IQuiz
}>()

const emit = defineEmits<{
  (e: 'showQuestionButtonClick'): void
}>()

const buttonText = computed(() => {
  if (props.quiz.state === QuizState.NEW) {
    return "Spustit kvíz";
  }
  if (props.quiz.state === QuizState.IN_PROGRESS) {
    return "Spustit kvíz";
  }
  if (props.quiz.state === QuizState.COMPLETED) {
    return "Prohlížet kvíz";
  }
  if (props.quiz.state === QuizState.EXPIRED) {
    return "Prohlížet kvíz";
  }
  return "";
})

function showQuestionButtonClick() {
  emit('showQuestionButtonClick')
}
</script>

<template>
  <div class="quiz-info">
    <div class="icon-box">
      <DocumentIcon aria-hidden="true" class="h-7 w-7 text-orange-500"/>
      <div>
        <div class="box-headline">Stav</div>
        {{ quiz?.state }}
      </div>
    </div>
    <div class="icon-box">
      <CalendarIcon aria-hidden="true" class="h-7 w-7 text-orange-500"/>
      <div>
        <div class="box-headline">Deadline</div>
        {{ quiz?.deadline }}
      </div>
    </div>
    <div class="icon-box">
      <ClockIcon aria-hidden="true" class="h-7 w-7 text-orange-500"/>
      <div>
        <div class="box-headline">Celkový čas</div>
        {{ millisecondsToString(quiz?.spendTime) }} / {{ millisecondsToString(quiz?.maxResponseTime) }}
      </div>
    </div>
    <div class="icon-box">
      <BoltIcon aria-hidden="true" class="h-7 w-7 bg-orange-500 rounded-full p-1"/>
      <div>
        <div class="box-headline">Získáno bodů</div>
        {{ quiz?.achievedPoints }} / {{ quiz?.maxPoints }}
      </div>
    </div>

    <div class="button-line">
      <MyButton @click="showQuestionButtonClick">{{ buttonText }}</MyButton>
    </div>
  </div>
</template>