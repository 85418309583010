<script setup lang="ts">
import {onMounted, ref} from "vue";
import {useQuizListItemStore} from "@/stores/quiz";
import {IQuiz} from "@/services/contracts/IQuiz";
import {ArrowLeftIcon} from '@heroicons/vue/24/outline';
import {QuizPageState} from "@/services/contracts/QuizPageState";
import QuestionResult from "@/components/QuestionResult.vue";
import QuestionDetail from "@/components/QuestionDetail.vue";
import QuizDetail from "@/components/QuizDetail.vue";
import MyMathjax from "@/components/MyMathjax.vue";

const quizListItemStore = useQuizListItemStore();
const quiz = ref(loadData());

function loadData(): IQuiz | null {
  const src = quizListItemStore.quiz;
  if (src == null) {
    return null;
  }
  return {
    id: src.id,
    name: src.name,
    state: src.state,
    deadline: src.deadline,
    questionCount: src.questionCount,
    answerCount: src.answerCount,
    maxPoints: src.questionCount * 50,
    maxResponseTime: src.questionCount * 2300,
    achievedPoints: src.answerCount * 50,
    spendTime: src.answerCount * 2300,

    answer: null,

    pageState: QuizPageState.INFO
  }
}

function loadQuestionDetailData() {
  quiz.value = loadData();
  if (quiz.value !== null) {
    quiz.value.answer = {
      id: 1,
      questionId: 1,
      questionText: 'When \\(a != 0\\), there are two solutions to \\(ax^2 + bx + c = 0\\) and\n' +
          "        they are $x = {-b +- sqrt{b^2-4ac} / (2a)}.$\n" +
          "        asdfasdf `omega = x^2` asdfasdf dasf\n" +
          "        nebo `sum_(i=1)^n i^3=((n(n+1))/2)^2`\n" +
          "        \n" +
          "        \\(ahoj = x^2\\)\n" +
          "        \\(sum_(i=1)^n i^3=((n(n+1))/2)^2\\)",
      questionChoices: [
        {id: 1, text: "První odpověď"},
        {id: 2, text: "Druhá odpověď (ta správná)"},
        {id: 3, text: "Třetí hrozně a hrozně a hrozně a hrozně a hrozně dlouhá odpověď"},
        {id: 4, text: "První odpověď"}
      ],
      answerTime: 20 * 1000,
      maxResponseTime: 30 * 80 + 20 * 1000,
      maxPoints: 50,
      elapsedTime: null,
      selectedChoiceId: null,
      correct: null,
      points: null
    }
  }
}

function showQuestionButtonClick() {
  loadQuestionDetailData();

  if (quiz.value !== null && quiz.value.answer !== null) {
    quiz.value.pageState = QuizPageState.QUESTION;
  }

}

function validateQuestionButtonClick(selectedChoice: number) {
  loadQuestionDetailData();
  if (quiz.value !== null && quiz.value.answer !== null) {
    if (selectedChoice > 0) {
      let answer = quiz.value.answer;
      answer.selectedChoiceId = selectedChoice;
      if (selectedChoice === 2) {
        answer.correct = true;
        answer.points = 50;
      } else {
        answer.correct = false;
        answer.points = 0;
      }
      answer.elapsedTime = 2000;

      quiz.value.pageState = QuizPageState.QUESTION_RESULT;
    }
  }
}

function nextQuestionButtonClick() {
  showQuestionButtonClick();
}

onMounted(() => {
  if (quiz.value == null) {
    window.location.href = '/';
  }
})
</script>

<template>
  <div class="quiz-header">
    <router-link to="/">
      <ArrowLeftIcon class="w-6 h-6"/>
    </router-link>
    <div class="h-10 w-10 p-1 bg-orange-500 rounded-full text-sm flex items-center justify-center">
      <span>{{ quiz?.answerCount }}/{{ quiz?.questionCount }}</span>
    </div>
    <div>{{ quiz?.name }} [{{ quiz?.id }}]</div>
  </div>

  <QuizDetail v-if="quiz?.pageState === QuizPageState.INFO"
              :quiz="quiz"
              @showQuestionButtonClick="showQuestionButtonClick"/>

  <QuestionDetail v-if="quiz?.pageState === QuizPageState.QUESTION"
                  :quiz="quiz"
                  @validateQuestionButtonClick="validateQuestionButtonClick"/>

  <QuestionResult v-if="quiz?.pageState === QuizPageState.QUESTION_RESULT"
                  :quiz="quiz"
                  @nextQuestionButtonClick="nextQuestionButtonClick"/>

</template>