import {defineStore} from "pinia";
import {Ref, ref} from "vue";
import VersionService from "@/services/VersionService";

export const useVersionStore = defineStore('version', () => {

    const versionChanged: Ref<boolean> = ref(false);
    const version: Ref<string> = ref('');

    function reloadOnVersionDifference() {
        check(true);
    }

    function check(forceReload = false) {
        VersionService.findVersion()
            .then((response) => {
                    const local = VersionService.get();
                    const remote = response.data;

                    if (local !== remote) {
                        console.log('Local version: ', local);
                        console.log('Remote version: ', remote);

                        version.value = remote;

                        if (forceReload) {
                            console.warn('Upgrading application to ' + version.value);
                            VersionService.set(version.value);
                            location.reload();
                        } else {
                            versionChanged.value = true;
                        }
                    } else {
                        version.value = local;
                        versionChanged.value = false;
                    }
                }
            )
            .catch((error) => console.error('Failed to check version', error));
    }

    // setInterval(check, 60000);

    return {version, versionChanged, reloadOnVersionDifference}
})