<script setup lang="ts">
import {computed, onMounted, ref} from "vue";
import {useVersionStore} from "@/stores/version";
import PageLayout from "@/components/PageLayout.vue";
import LogoutButton from "@/components/LogoutButton.vue";
import MyButton from "@/components/MyButton.vue";
import MyInput from "@/components/MyInput.vue";
import {useExoUserStore} from "@/stores/exouser";
import exoUserService from "@/services/ExoUserService";
import {IExoUser} from "@/services/contracts/IExoUser";
import {ArrowUpCircleIcon, DocumentTextIcon, EnvelopeIcon} from "@heroicons/vue/24/outline";

const exoUserStore = useExoUserStore();
const versionStore = useVersionStore();

const busy = ref(false);
const invalid = computed(() => {
  return exoUserStore.exoUser.firstName == ''
      || exoUserStore.exoUser.lastName == ''
      || exoUserStore.exoUser.nickname == '';
});
const image = ref();
const fileInput = ref();
const preferredEmail = ref();
const firstName = ref();
const lastName = ref();
const nickname = ref();

const data = computed(() => {
  return exoUserStore.exoUser.profileImageData == null ? "" : exoUserStore.exoUser.profileImageData;
})

const accountName = computed(() => {
  if (exoUserStore.exoUser.ssoId.startsWith('google')) {
    return 'Google ' + exoUserStore.exoUser.ssoUsername;
  } else if (exoUserStore.exoUser.ssoId.startsWith('apple')) {
    return 'Apple ' + exoUserStore.exoUser.ssoUsername;
  }
  return exoUserStore.exoUser.ssoUsername;
})

onMounted(() => {
  versionStore.reloadOnVersionDifference();
  exoUserStore.load();
})

function saveChanges() {

  const eu: IExoUser = {
    id: exoUserStore.exoUser.id,
    ssoId: exoUserStore.exoUser.ssoId,
    firstName: exoUserStore.exoUser.firstName,
    lastName: exoUserStore.exoUser.lastName,
    nickname: exoUserStore.exoUser.nickname,
    preferredEmail: exoUserStore.exoUser.preferredEmail,
    profileImageData: exoUserStore.profileImageChanged ? exoUserStore.exoUser.profileImageData : null,
    ssoUsername: null,
    email: null,
  };

  busy.value = true;

  exoUserService.patch(exoUserStore.exoUser.ssoId, eu)
      .then((res) => {
        exoUserStore.exoUser = res.data;
      })
      .finally(() => {
        busy.value = false;
      });
}

function cropAndResizeImage(imgElement: HTMLImageElement, outputSize = 256): string {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

  canvas.width = outputSize;
  canvas.height = outputSize;

  const imgWidth = imgElement.naturalWidth;
  const imgHeight = imgElement.naturalHeight;

  const cropSize = Math.min(imgWidth, imgHeight);
  const cropX = (imgWidth - cropSize) / 2;
  const cropY = (imgHeight - cropSize) / 2;

  ctx.drawImage(imgElement, cropX, cropY, cropSize, cropSize, 0, 0, outputSize, outputSize);

  return canvas.toDataURL();
}

function loadFromFile(ev: any) {

  console.log('Loading from file..');

  const files = ev.target.files;

  if (files && files.length) {
    const reader = new FileReader();

    reader.onload = function () {
      image.value.title = files[0].name;

      const tmpImg = document.createElement('img') as HTMLImageElement;

      tmpImg.onload = function () {
        exoUserStore.exoUser.profileImageData = cropAndResizeImage(tmpImg);
        exoUserStore.profileImageChanged = true;
      }

      tmpImg.src = reader.result as string;
    }

    reader.readAsDataURL(files[0]);
  }
}

function checkFirstName() {
  if (exoUserStore.exoUser.firstName == '') {
    firstName.value.setValid(false, 'Povinné');
  } else {
    firstName.value.setValid(true, '');
  }
}

function checkLastName() {
  if (exoUserStore.exoUser.lastName == '') {
    lastName.value.setValid(false, 'Povinné');
  } else {
    lastName.value.setValid(true, '');
  }
}

function checkNickname() {
  if (exoUserStore.exoUser.nickname == '') {
    nickname.value.setValid(false, 'Povinné');
  } else {
    nickname.value.setValid(true, '');
  }
}
</script>

<template>
  <PageLayout>
    <div class="pb-1 flex flex-col items-center relative">
      <img ref="image" class="inline-block rounded-full border-cez-color border-2 profile-image" :src="data" alt=""/>
      <input @change="loadFromFile" ref="fileInput" type="file" hidden/>
      <div class="w-10 h-10 bg-cez-color rounded-full cursor-pointer text-white"
           style="margin-top: -50px; margin-left: 160px;"
           title="Nahrát obrázek" @click="fileInput.click()">
        <ArrowUpCircleIcon aria-hidden="true"/>
      </div>
    </div>

    <div class="grid grid-cols-1 gap-y-2 ">
      <MyInput label="Účet" disabled type="text"
               v-model="accountName" name="ssoUsername"/>

      <MyInput label="Email" disabled type="text" ref="preferredEmail"
               v-model="exoUserStore.exoUser.preferredEmail" name="preferredEmail"/>

      <MyInput label="Jméno" type="text" ref="firstName"
               @input="checkFirstName"
               v-model="exoUserStore.exoUser.firstName" name="firstName"/>

      <MyInput label="Příjmení" type="text" ref="lastName"
               @input="checkLastName"
               v-model="exoUserStore.exoUser.lastName" name="lastName"/>

      <MyInput label="Přezdívka" type="text" ref="nickname"
               @input="checkNickname"
               v-model="exoUserStore.exoUser.nickname" name="nickname"/>
    </div>

    <div class="py-4">
      <MyButton @click="saveChanges" :disabled="busy || invalid">Uložit změny</MyButton>
    </div>

    <div class="grid grid-cols-1 gap-y-2 mt-6">
      <a class="link-box" href="/pravidla-a-podminky" target="_blank">
        <DocumentTextIcon class="h-5 w-5 text-cez-color" aria-hidden="true"/>
        <span>Pravidla a podmínky</span>
      </a>

      <a class="link-box" href="/gdpr" target="_blank">
        <DocumentTextIcon class="h-5 w-5 text-cez-color" aria-hidden="true"/>
        <span>GDPR</span>
      </a>

      <a class="link-box" href="/cez" target="_blank">
        <EnvelopeIcon class="h-5 w-5 text-cez-color" aria-hidden="true"/>
        <span>Kontaktuj ČEZ</span>
      </a>
    </div>

    <div class="py-4">
      <LogoutButton/>
    </div>

    <div class="text-gray-600 text-sm text-center p-2">{{ versionStore.version }}</div>
  </PageLayout>
</template>

<style scoped>
.profile-image {
  width: 256px;
  height: 256px;
}

.link-box {
  @apply bg-gray-900 p-2 mb-1 rounded-md border-cez-color border flex items-center gap-x-2 text-sm;
}

</style>