import {IQuizListItem} from "@/services/contracts/IQuizListItem";

class QuizListItemService {

    get(): IQuizListItem | null {
        const val = localStorage.getItem("quizListItem");
        if (val === null) {
            return null;
        }
        return JSON.parse(val);
    }

    set(quiz: IQuizListItem | null): void {
        if (quiz === null) {
            this.remove();
        } else {
            localStorage.setItem("quizListItem", JSON.stringify(quiz));
        }
    }

    remove(): void {
        localStorage.removeItem("quizListItem");
    }

}

export default new QuizListItemService();
