<template>
  <PageLayout v-if="error">
    <h1>Error</h1>
    <span>{{ error.message }}</span>
  </PageLayout>
</template>

<script setup lang="ts">
import {useAuth0} from "@auth0/auth0-vue";
import PageLayout from "@/components/PageLayout.vue";

const {error} = useAuth0();
</script>
