<template>

<HomeProfileBanner/>
    <HomeScoreBoard/>
    <HomeQuizList/>

</template>

<script setup lang="ts">
import HomeProfileBanner from "@/components/HomeProfileBanner.vue";
import HomeQuizList from "@/components/HomeQuizList.vue";
import HomeScoreBoard from "@/components/HomeScoreBoard.vue";
import {onMounted} from "vue";
import {useExoUserStore} from "@/stores/exouser";
import {useVersionStore} from "@/stores/version";

const exoUserStore = useExoUserStore();
const versionStore = useVersionStore();

onMounted(() => {
  versionStore.reloadOnVersionDifference();
  exoUserStore.load();
})
</script>

<style>
</style>