import {Ref, ref} from "vue";
import {defineStore} from "pinia";
import {IQuizListItem} from "@/services/contracts/IQuizListItem";
import quizListItemService from "@/services/QuizListItemService";

export const useQuizListItemStore
    = defineStore('quiz', () => {

    const quiz: Ref<IQuizListItem | null> = ref(quizListItemService.get())

    function changeQuiz(newQuiz: IQuizListItem | null): void {
        quiz.value = newQuiz;
        quizListItemService.set(newQuiz);
    }

    return {quiz, changeQuiz}
})