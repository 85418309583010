import api from "./support/api";
import {IExoUser} from "@/services/contracts/IExoUser";
import {AxiosResponse} from "axios";

class ExoUserService {

    get(ssoid: string): Promise<AxiosResponse<IExoUser>> {
        return api.get('/exouser/' + ssoid);
    }

    patch(ssoid: string, user: IExoUser): Promise<AxiosResponse<IExoUser>> {
        return api.patch<IExoUser>('/exouser/' + ssoid, user);
    }
}

export default new ExoUserService();
