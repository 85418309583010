import api from "./api";
import {auth0} from "@/services/support/auth0";

const setup = () => {
    api.interceptors.request.use(
        async (config) => {
            const accessToken = await auth0.getAccessTokenSilently()
            config.headers['Authorization'] = `Bearer ${accessToken}`;
            return config;
        }, (error) => {
            return Promise.reject(error)
        });
};

export default setup;