<template>
  <div>
    <label :for="name" class="block text-xs font-medium leading-6 text-gray-400">{{ label }}</label>
    <div class="relative mt-0.5 rounded-md shadow-sm">
      <input :type="type"
             :name="name"
             :disabled="disabled"
             class="block w-full rounded-md border-0 py-1.5
                    ring-1 ring-inset ring-gray-600 focus:ring-2 focus:ring-inset
                    disabled:text-gray-500
                    text-sm leading-6 bg-gray-700 text-white"
             :class="valid ?
                    'text-gray-900 shadow-sm ring-gray-300 placeholder:text-gray-400 focus:ring-cez-color'
                    : 'pr-10 text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'"
             :value="modelValue"
             @input="updateModelValue"
      />
      <div v-if="!valid" class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
        <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true"/>
      </div>
    </div>
    <p v-if="!valid" class="mt-2 text-sm text-red-600">{{ message }}</p>
  </div>
</template>

<script lang="ts" setup>
import {ExclamationCircleIcon} from '@heroicons/vue/20/solid'
import {ref} from "vue";

withDefaults(defineProps<{
  modelValue: any,
  name: string,
  label: string,
  type?: string,
  disabled?: boolean,
}>(), {type: 'text', disabled: false});

const emit = defineEmits<{
  (e: "update:modelValue", value: string): string;
}>();

const valid = ref(true);
const message = ref('');

const updateModelValue = (e: Event) => {
  emit('update:modelValue', (e.target as HTMLInputElement).value)
};

function setValid(_valid: boolean, _message: string = '') {
  valid.value = _valid;
  message.value = _message
}

defineExpose({
  setValid
})
</script>

<style scoped>

</style>