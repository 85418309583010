<template>
  <router-view/>
</template>

<style>
</style>

<script setup lang="ts">
import {useAuth0} from "@auth0/auth0-vue";

// TODO: dodelat watch('isLoading) - tocit vrtuli dokud nebude auth0 ready?
const {isLoading} = useAuth0();
</script>